import axios from "axios";
import store from "../store";
import { USER_LOGOUT } from "./userConstants";
import { createBrowserHistory } from "history";
// const local=
const api = axios.create({
  // baseURL: "https://osman.medicalit.info/app/api/v1/",
  baseURL: "https://zhyan.medicalit.digital/app/public/api/v1/",
  withCredentials: false,
  headers: { "Access-Control-Allow-Origin": "*" },
  paramsSerializer: (params) => {
    // Sample implementation of query string building
    let result = "";
    Object.keys(params).forEach((key) => {
      result += `${key}=${encodeURIComponent(params[key])}&`;
    });
    
    return result;
  },
  credentials: "Access-Control-Allow-Credentials",
});

export default api;
export async function logout() {
  localStorage.removeItem("userInfo");
  store.dispatch({ type: USER_LOGOUT });

  setTimeout(function () {
    

    createBrowserHistory().push("/");

    window.location.reload();
  }, 1000);
}
